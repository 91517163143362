import { Grid } from "@material-ui/core";
import React from "react";
import PlainCard from "../../components/PlainCard";
import "./ThreePlainCards.scss";

const ThreePlainCards = (props) => {
  return (
    <div className="body-container">
      <Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-odd"
              HoverCardWithBtntitle={
                props.ThreeHoverCardData.HoverCardWithBtntitle1
              }
              Extracardclassname={props.ThreeHoverCardData.Extracardclassname}
              HoverCardExtraiconclassname={
                props.ThreeHoverCardData.HoverCardExtraiconclassname
              }
              HoverCardWithBtnIcon={
                props.ThreeHoverCardData.HoverCardWithBtnIcon1
              }
              HoverCardWithBtnContent={
                props.ThreeHoverCardData.HoverCardWithBtnContent1
              }
              HoverCardWithBtnContentClass={
                props.ThreeHoverCardData.HoverCardWithBtnContentClass
              }
              HoverCardWithBtnIconAlt={
                props.ThreeHoverCardData.HoverCardWithBtnIconAlt1
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-even"
              HoverCardWithBtntitle={
                props.ThreeHoverCardData.HoverCardWithBtntitle2
              }
              Extracardclassname={props.ThreeHoverCardData.Extracardclassname}
              HoverCardExtraiconclassname={
                props.ThreeHoverCardData.HoverCardExtraiconclassname
              }
              HoverCardWithBtnIcon={
                props.ThreeHoverCardData.HoverCardWithBtnIcon2
              }
              HoverCardWithBtnContent={
                props.ThreeHoverCardData.HoverCardWithBtnContent2
              }
              HoverCardWithBtnContentClass={
                props.ThreeHoverCardData.HoverCardWithBtnContentClass
              }
              HoverCardWithBtnIconAlt={
                props.ThreeHoverCardData.HoverCardWithBtnIconAlt2
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-odd"
              HoverCardWithBtntitle={
                props.ThreeHoverCardData.HoverCardWithBtntitle3
              }
              Extracardclassname={props.ThreeHoverCardData.Extracardclassname}
              HoverCardExtraiconclassname={
                props.ThreeHoverCardData.HoverCardExtraiconclassname
              }
              HoverCardWithBtnIcon={
                props.ThreeHoverCardData.HoverCardWithBtnIcon3
              }
              HoverCardWithBtnContent={
                props.ThreeHoverCardData.HoverCardWithBtnContent3
              }
              HoverCardWithBtnContentClass={
                props.ThreeHoverCardData.HoverCardWithBtnContentClass
              }
              HoverCardWithBtnIconAlt={
                props.ThreeHoverCardData.HoverCardWithBtnIconAlt3
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThreePlainCards;
