import React, { useEffect } from "react";
import "./MobileSmoothSlider.scss";

const MovieSlider = (props) => {
  useEffect(() => {
    document.getElementById(
      "tech-slideshow-1"
    ).style.backgroundImage = `url(${props.movieSlider.imageUrl})`;
  }, [props.movieSlider.imageUrl]);
  return (
    <div className= {props.reduceheight === "no" ? "tech-slideshow-smooth1" :"tech-slideshow-smooth"}>
      <div id="tech-slideshow-1" className="smooth-mover-1"></div>
    </div>
  );
};

export default MovieSlider;
