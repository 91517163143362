import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import HoverCardWithBtnIcon1 from "../assets/Icons/e_commerce.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import HoverCardWithBtnIcon3 from "../assets/Icons/Platformn_Based_Product_Development.svg";
import HoverCardWithBtnIcon2 from "../assets/Icons/saas.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import scrollImage from "../assets/Images/DigitalTransformation/2_2x.png";
import BannerBgImage from "../assets/Images/DigitalTransformation/Digital_Transformation.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import MiniImageBannerBgImg1 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import ogimage from "../assets/Images/ogImages/OG_Digital-Transformation.png";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
//PlainCard
import PlainCard from "../components/SixPlainCard";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//BlurCardList
import BlurCardList from "../templates/BlurCardList";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Miniview
import Miniview from "../templates/MiniView";
//Six Hover Cards
import ThreePlainCards from "../templates/ThreePlainCards";
// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const DigitalTransformation = () => {
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Digital Transformation"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Digital Transformation&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <div>
        Digital <br /> Transformation
      </div>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Digital Disruption for <br />
        <span className="red-line3letter">Bus</span>iness Transformation
      </div>
    ),
    InfoviewContent: (
      <>
        Transform your traditional business model and give it a new digital
        identity to adapt to changing circumstances. At Digiryte, we can help
        you with your digital transition to build a product for the future. We
        take into account factors that reshape the world, such as technology,
        globalisation and demographics, and empower your business to seize
        opportunities in the new world.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Six Hover Card
   */

  const ThreeHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Customer Experience Designing",
    HoverCardWithBtntitle2: "Business Process Automation",
    HoverCardWithBtntitle3: "New Digital Business Model",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnContent1:
      "We design focusing on quality user experience. We optimise customer experiences at all touchpoints before, during and after conversion.",
    HoverCardWithBtnContent2:
      "We help our clients to embrace Business Process Automation to drive business efficiency, reduce costs and save time & resources to intelligently solve challenges.",
    HoverCardWithBtnContent3:
      "We extend your existing analogue business model by adding digital components to help your business confront the digitalisation of this era.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    route: "/contact-us",
  };

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Microservices based",
        HoverCardWithBtnsubtitle: "(Improves Agility)",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Microservices based",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "API-first",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "API-first",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
      },
      {
        HoverCardWithBtntitle: "Cloud-native SaaS",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Cloud-native SaaS",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
      },
      {
        HoverCardWithBtntitle: "Headless",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Headless",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Months to Minutes)",
      },
      {
        HoverCardWithBtntitle: "ISO 27001 Compliant",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "ISO 27001 Compliant",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Lifecyle)",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg1,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: scrollImage,
  };
  return (
    <Layout
      seo={{
        title: "Digital Transformation | Digiryte",
        ogtitle: "Digital Transformation | Digiryte",
        description:
          "Digiryte helps businesses transform their traditional models into digital-first operations, enhancing adaptability and driving growth through innovative technology solutions.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/digital-transformation`,
        keywords:
          "digital transformation, business model, digital identity, adaptability, growth, technology solutions, Digiryte",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />

      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="body-container ttc">
        <span className="title-header">
          Build the 3 Pillars of <br />{" "}
          <span className="red-line3letter">Dig</span>ital Transformation
        </span>
      </div>
      <div className="mt-50"></div>
      <ThreePlainCards ThreeHoverCardData={ThreeHoverCardData} />
      <div className="mt-70 body-container ttc">
        <span className="title-header mbl-br-hide">
          Our digital transformation process <br />{" "}
          <span className="red-line3letter">Inc</span>ludes
        </span>
      </div>
      <div className="body-container">
        <Grid
          className="mt-50  hide-in-mobile"
          container
          direction="row"
          spacing={3}
        >
          {PlainCardData.cardsData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card className="appsgridshadow">
                <PlainCard
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}

          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <Card className="appsgridshadow mini-card-bg-hght1">
              <MiniCardWithBgImg
                MiniCardBgImg={PlainCardData.lastCardData.image}
                MiniCardBgImgId="100"
                tip="Get In Touch"
                tooltip="yes"
                title={PlainCardData.lastCardData.title}
                subtitle={PlainCardData.lastCardData.subTitle}
                variant={PlainCardData.lastCardData.variant}
                customClassName="three-mini-card-btn mt-20"
                cardCustomClass={PlainCardData.lastCardData.customClass}
                label={PlainCardData.lastCardData.label}
                route={PlainCardData.lastCardData.route}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider} />
      </Grid>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container ttc">
            <span className="title-header">
              <span className="red-line3letter">Rec</span>ommended Reading
            </span>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70 ttc">
        <span className="title-header">
          <span className="red-line3letter">Oth</span>er Services
        </span>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default DigitalTransformation;
